<template>
  <v-toolbar color="transparent" elevation="0" dense height="20">
    <v-btn small outlined rounded
          :color="report.status == 'OPEN' ? 'red' : 'blue'" 
          class="mx-2" @click="openDialogReport()" 
          title="afficher les signalements">
      <b class="pr-1">{{ report.reporters ? report.reporters.length : 0 }}</b> reports
    </v-btn>
    
    <b class="pl-2" :class="report.user.status == 'OPEN' ? 'red--text' : 'blue--text'">
      <v-icon :color="report.user.status == 'OPEN' ? 'red' : 'blue'" class="mb-1" small>mdi-shield-alert-outline</v-icon>
      {{ report.status }}  
    </b>
    <b class="pl-2" :class="report.user.status == 'OPEN' ? 'green--text' : 'red--text'">
      <v-icon :color="report.user.status == 'OPEN' ? 'green' : 'red'" class="mb-1" small>mdi-basket</v-icon>
      {{ report.user.status }}
    </b>
    
    <v-spacer/>
    <v-btn small icon outlined class="mx-1" @click="openDialogActions(report)"
          title="gérer ce signalement">
      <v-icon small>mdi-cog</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>

export default {
  name: 'top-bar-user',
  components: {
    
  },
  props: [ 'report', 'user', 'openDialogActions', 'openDialogReport' ],
  data: () => ({
  }),
  mounted: async function(){
    
  },
  methods: {
    
  },
  computed: {
  },
}
</script>
